// import { ref, computed } from 'vue'
import mitt from 'mitt'
const emitter = mitt()

export default function useMitt() {
    const toggleLoading = (isLoading: boolean) => {
        emitter.emit('showLoading', isLoading)
    }
    return {
        emitter,
        toggleLoading
    }
}
