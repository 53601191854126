export enum permission {
    DASHBOARD = 'DASHBOARD',
    DASHBOARD_V2 = 'DASHBOARD_V2',
    LOTTO = 'LOTTO',
    USER_MANAGE = 'USER_MANAGE',
    ADMIN_MANAGE = 'ADMIN_MANAGE',
    BANK_MANAGE = 'BANK_MANAGE',
    DEPOSIT = 'DEPOSIT',
    WITHDRAW = 'WITHDRAW',
    BILL_REPORT = 'BILL_REPORT',
    BILL_SUMMARY = 'BILL_SUMMARY',
    COMM_SUMMARY = 'COMM_SUMMARY',
    COMM_SUMMARY_CASINO_GAME = 'COMM_SUMMARY_CASINO_GAME',
    BILL_PENDING = 'BILL_PENDING',
    PERMISSION = 'PERMISSION',
    NEW_USER_DEPOSIT = 'NEW_USER_DEPOSIT',
    PROFIT_REPORT = 'PROFIT_REPORT',
    APP_CONFIG = 'APP_CONFIG',
    TRANSFER = 'TRANSFER',
    LOGS = 'LOGS',
    GAME_REPORT = 'GAME_REPORT',
    BONUS_FORFEIT = 'BONUS_FORFEIT',
    REPORT_PROMOTION = 'REPORT_PROMOTION',
    TIERS = 'TIERS',
    PROVIDER = 'PROVIDER',
    PROVIDER_V2 = 'PROVIDER_V2',
    SMS = 'SMS'
}

interface PermissionList {
    value: permission
    name: string
}

export const permissionList: PermissionList[] = [
    { value: permission.DASHBOARD, name: 'Dashboard' },
    { value: permission.LOTTO, name: 'จัดการหวย' },
    { value: permission.USER_MANAGE, name: 'บัญชีลูกค้า' },
    { value: permission.ADMIN_MANAGE, name: 'บัญชีผู้ดูแลระบบ' },
    { value: permission.BANK_MANAGE, name: 'บัญชีธนาคาร' },
    { value: permission.DEPOSIT, name: 'ฝากเงิน' },
    { value: permission.WITHDRAW, name: 'ถอนเงิน' },
    { value: permission.BILL_REPORT, name: 'รายการแทง' },
    { value: permission.BILL_SUMMARY, name: 'สรุป' },
    { value: permission.COMM_SUMMARY, name: 'สรุปค่าคอม' },
    { value: permission.COMM_SUMMARY_CASINO_GAME, name: 'สรุปค่าคอม (คาสิโน/เกม)' },
    { value: permission.BILL_PENDING, name: 'รอผลเดิมพัน' },
    { value: permission.GAME_REPORT, name: 'รายงานเกม' },
    { value: permission.PERMISSION, name: 'สิทธิ์การใช้งาน' },
    { value: permission.NEW_USER_DEPOSIT, name: 'สมาชิกใหม่ฝากเงิน' },
    { value: permission.PROFIT_REPORT, name: 'รายงานสมาชิกฝาก - ถอน' },
    { value: permission.APP_CONFIG, name: 'ตั้งค่าเว็บ' },
    { value: permission.TRANSFER, name: 'ระบบโอนเงิน' },
    { value: permission.LOGS, name: 'Logs' },
    { value: permission.BONUS_FORFEIT, name: 'เติม/ถอนเครดิต' },
    { value: permission.REPORT_PROMOTION, name: 'รายงานโปรโมชัน' },
    { value: permission.TIERS, name: 'ระดับลูกค้า' },
    { value: permission.PROVIDER, name: 'จัดการค่ายเกม' },
    { value: permission.PROVIDER_V2, name: 'จัดการค่ายเกม V2' },
    { value: permission.SMS, name: 'ส่ง SMS' }
]
