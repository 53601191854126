import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/sass/app.scss'
import Swal from './utils/useSwal'
import globalComponents from './global-components'
import './libs'
import PrimeVue from 'primevue/config'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import VCalendar from 'v-calendar'
import Tooltip from 'primevue/tooltip'

const app = createApp(App).use(store).use(router).use(VCalendar, {})
app.use(Swal)
app.use(PrimeVue)
app.use(ConfirmationService)
app.use(ToastService)

app.directive('tooltip', Tooltip)

globalComponents(app)
app.mount('#app')
