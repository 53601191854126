import { inject } from 'vue'
import Swal from 'sweetalert2'
const SwalSymbol = Symbol()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSwal(): any {
    return inject(SwalSymbol)
}

export default {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    install: (app: any) => {
        app.config.globalProperties.$Swal = Swal
        app.provide(SwalSymbol, Swal)
    }
}
