
import ProgressSpinner from 'primevue/progressspinner'
import { defineComponent, onMounted, ref } from 'vue'
import useMitt from '@/composables/useMitt'
export default defineComponent({
    components: {
        ProgressSpinner
    },
    setup() {
        const isLoading = ref(false)
        const { emitter } = useMitt()
        onMounted(() => {
            emitter.on('showLoading', (load: any) => {
                isLoading.value = load
            })
        })
        return {
            isLoading
        }
    }
})
