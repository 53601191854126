import { createStore, Store } from 'vuex'
import sideMenu from './sideMenu'
import router from '@/router/index'
import { authService } from '@/service/auth'
import { InjectionKey } from 'vue'

interface Permission {
    id: number
    name: string
    active: boolean
    roles: string[]
}
interface AdminDetail {
    id: number
    username: string
    active: boolean
    permissionId: number
    remark?: any
    createdAdminId?: any
    permission: Permission
}
interface State {
    adminDetail: AdminDetail | null
}
export const key: InjectionKey<Store<State>> = Symbol()
export default createStore<State>({
    state: {
        adminDetail: null
    },
    getters: {
        roleAdmin: (state) => {
            return state.adminDetail?.permission.roles
        },
        adminUsername: (state) => {
            return state.adminDetail?.username
        },
        adminRoleName: (state) => {
            return state.adminDetail?.permission.name
        }
    },
    mutations: {
        logout(state) {
            window.localStorage.removeItem('lotto')
            state.adminDetail = null
            router.push({ name: 'Login' })
        },
        setAdminDetail(state, data) {
            state.adminDetail = data
        }
    },
    actions: {
        async fetchUserDetail({ commit }) {
            try {
                const result = await authService.fetchUserDetail()
                if (result.status === 200) {
                    commit('setAdminDetail', result.data)
                } else {
                    commit('logout')
                }
            } catch (error) {
                commit('logout')
            }
        }
    },
    modules: {
        sideMenu
    }
})
