import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { permission } from '@/type/Permission'
import store from '@/store'
const errorPage = () => import(/* webpackChunkName: "ErrorPage" */ '../views/ErrorPage.vue')

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Main',
        component: () => import(/* webpackChunkName: "SideMenu" */ '../layout/SideMenu.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: '/dashboard',
                name: permission.DASHBOARD,
                meta: { permission: permission.DASHBOARD },
                component: () =>
                    import(/* webpackChunkName: "DashBoard" */ '../views/Dashboard/Home.vue')
            },
            {
                path: '/user/manage',
                name: permission.USER_MANAGE,
                meta: { permission: permission.USER_MANAGE },
                component: () =>
                    import(/* webpackChunkName: "UserManage" */ '../views/Dashboard/UserManage.vue')
            },
            {
                path: '/admin/manage',
                name: permission.ADMIN_MANAGE,
                meta: { permission: permission.ADMIN_MANAGE },
                component: () =>
                    import(
                        /* webpackChunkName: "AdminManage" */ '../views/Dashboard/AdminManage.vue'
                    )
            },
            {
                path: '/payment/bank/manage',
                name: permission.BANK_MANAGE,
                meta: { permission: permission.BANK_MANAGE },
                component: () =>
                    import(/* webpackChunkName: "bankManage" */ '../views/Dashboard/BankManage.vue')
            },
            {
                path: '/deposit/manage',
                name: permission.DEPOSIT,
                meta: { permission: permission.DEPOSIT },
                component: () =>
                    import(
                        /* webpackChunkName: "depositManage" */ '../views/Dashboard/DepositManage.vue'
                    )
            },
            {
                path: '/withdraw/manage',
                name: permission.WITHDRAW,
                meta: { permission: permission.WITHDRAW },
                component: () =>
                    import(
                        /* webpackChunkName: "withdrawManage" */ '../views/Dashboard/WithdrawManage.vue'
                    )
            },
            {
                path: '/lotto/:betType?',
                name: permission.LOTTO,
                meta: { permission: permission.LOTTO },
                component: () =>
                    import(
                        /* webpackChunkName: "LottoTypeList" */ '../views/Lotto/LottoTypeList.vue'
                    )
            },
            {
                path: '/lotto/rounds/:lottoId',
                name: 'RoundList',
                meta: { permission: permission.LOTTO },
                component: () =>
                    import(/* webpackChunkName: "RoundList" */ '../views/Round/RoundList.vue')
            },
            {
                path: '/lotto/rounds/detail/:roundId/:status',
                name: 'RoundDetail',
                meta: { permission: permission.LOTTO },
                component: () =>
                    import(/* webpackChunkName: "RoundDetail" */ '../views/Round/RoundDetail.vue')
            },
            {
                path: '/user/:userId/:tabStatus',
                name: 'UserDetail',
                meta: { permission: permission.USER_MANAGE },
                component: () =>
                    import(/* webpackChunkName: "UserDetail" */ '../views/User/UserDetail.vue')
            },
            {
                path: '/report/billReport',
                name: permission.BILL_REPORT,
                meta: { permission: permission.BILL_REPORT },
                component: () =>
                    import(/* webpackChunkName: "billReport" */ '../views/Report/BillReport.vue')
            },
            {
                path: '/report/summaryReport',
                name: permission.BILL_SUMMARY,
                meta: { permission: permission.BILL_SUMMARY },
                component: () =>
                    import(
                        /* webpackChunkName: "summaryReport" */ '../views/Report/SummaryReport.vue'
                    )
            },
            {
                path: '/report/summaryCommReport',
                name: permission.COMM_SUMMARY,
                meta: { permission: permission.COMM_SUMMARY },
                component: () =>
                    import(
                        /* webpackChunkName: "summaryReport" */ '../views/Report/CommSummaryReport.vue'
                    )
            },

            {
                path: '/report/CommissionSummary',
                name: permission.COMM_SUMMARY_CASINO_GAME,
                meta: { permission: permission.COMM_SUMMARY_CASINO_GAME },
                component: () =>
                    import(
                        /* webpackChunkName: "summaryReport" */ '../views/Commission/CommissionSummary.vue'
                    )
            },
            {
                path: '/report/v2/CommissionSummary',
                name: 'COMM_SUMMARY_CASINO_GAME_V2',
                meta: { permission: permission.COMM_SUMMARY_CASINO_GAME },
                component: () =>
                    import(
                        /* webpackChunkName: "summaryReport" */ '../views/Commission/v2/CommissionSummary.vue'
                    )
            },
            {
                path: '/report/billPending',
                name: permission.BILL_PENDING,
                meta: { permission: permission.BILL_PENDING },
                component: () =>
                    import(
                        /* webpackChunkName: "summaryReport" */ '../views/Report/BillPending.vue'
                    )
            },
            {
                path: '/newUserDeposit',
                name: permission.NEW_USER_DEPOSIT,
                meta: { permission: permission.NEW_USER_DEPOSIT },
                component: () =>
                    import(
                        /* webpackChunkName: "NewUserDeposit" */ '../views/More/NewUserDeposit.vue'
                    )
            },
            {
                path: '/reportUserProfit',
                name: permission.PROFIT_REPORT,
                meta: { permission: permission.PROFIT_REPORT },
                component: () =>
                    import(
                        /* webpackChunkName: "ReportUserProfit" */ '../views/More/ReportUserProfit.vue'
                    )
            },
            {
                path: '/promotion',
                name: 'promotion',
                meta: { permission: permission.APP_CONFIG },
                component: () =>
                    import(/* webpackChunkName: "AppConfig" */ '../views/settings/Promotion.vue')
            },
            {
                path: '/appconfig',
                name: permission.APP_CONFIG,
                meta: { permission: permission.APP_CONFIG },
                component: () =>
                    import(/* webpackChunkName: "AppConfig" */ '../views/More/AppConfig.vue')
            },
            {
                path: '/permission',
                name: permission.PERMISSION,
                meta: { permission: permission.PERMISSION },
                component: () =>
                    import(/* webpackChunkName: "Permission" */ '../views/PermissionList.vue')
            },
            {
                path: '/transfer',
                name: permission.TRANSFER,
                meta: { permission: permission.TRANSFER },
                component: () =>
                    import(/* webpackChunkName: "transfer" */ '../views/Dashboard/Transfer.vue')
            },
            {
                path: '/logs',
                name: permission.LOGS,
                meta: { permission: permission.LOGS },
                component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue')
            },
            {
                path: '/report/reportCasinoGame',
                name: 'ReportCasinoGame',
                meta: { permission: permission.GAME_REPORT },
                component: () =>
                    import(
                        /* webpackChunkName: "gameReport" */ '../views/Report/casino-game/ReportCasinoGame.vue'
                    )
            },
            {
                path: '/report/v2/reportCasinoGame',
                name: 'ReportCasinoGameV2',
                meta: { permission: permission.GAME_REPORT },
                component: () =>
                    import(
                        /* webpackChunkName: "gameReport" */ '../views/Report/casino-game/v2/ReportCasinoGame.vue'
                    )
            },
            {
                path: '/report/promotion',
                name: 'ReportPromotion',
                meta: { permission: permission.GAME_REPORT },
                component: () =>
                    import(
                        /* webpackChunkName: "gameReport" */ '../views/Report/promotion/ReportPromotion.vue'
                    )
            },
            {
                path: '/tiers',
                name: permission.TIERS,
                meta: { permission: permission.TIERS },
                component: () => import(/* webpackChunkName: "logs" */ '../views/Tiers.vue')
            },
            {
                path: '/providers',
                name: permission.PROVIDER,
                meta: { permission: permission.PROVIDER },
                component: () =>
                    import(/* webpackChunkName: "logs" */ '../views/settings/SettingProvider.vue')
            },
            {
                path: '/v2/providers',
                name: permission.PROVIDER_V2,
                meta: { permission: permission.PROVIDER_V2 },
                component: () =>
                    import(/* webpackChunkName: "logs" */ '../views/settings/SettingProviderV2.vue')
            },
            {
                path: '/sms',
                name: permission.SMS,
                meta: { permission: permission.SMS },
                component: () => import(/* webpackChunkName: "sms" */ '../views/Sms.vue')
            }
        ],
        beforeEnter: async (to, from, next) => {
            if (from.name !== 'Login') {
                await store.dispatch('fetchUserDetail')
            }
            next()
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
    },
    {
        path: '/error-page',
        name: 'error-page',
        component: errorPage
    },
    {
        path: '/:pathMatch(.*)*',
        component: errorPage
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    let isAuthenticated = false
    const storage = window.localStorage.getItem('lotto')
    if (storage) {
        isAuthenticated = true
    }

    if (to.meta.requiresAuth && !isAuthenticated) next({ name: 'Login' })
    else if (to.name == 'Main' && isAuthenticated) {
        await store.dispatch('fetchUserDetail')
        next({ name: store.getters.roleAdmin[0] })
    } else {
        next()
    }
})

export default router
