
import { defineComponent, ref, inject, onMounted } from 'vue'
import tippy, { roundArrow, animateFill } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'
export default defineComponent({
    props: {
        content: {
            type: String,
            required: true
        },
        tag: {
            type: String,
            default: 'span'
        },
        options: {
            type: Object,
            default: () => ({})
        },
        refKey: {
            type: String,
            default: null
        }
    },
    setup(props) {
        const tippyRef = ref<HTMLElement>()
        const init = () => {
            tippy(tippyRef.value as HTMLElement, {
                plugins: [animateFill],
                content: props.content,
                arrow: roundArrow,
                popperOptions: {
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport'
                            }
                        }
                    ]
                },
                animateFill: false,
                animation: 'shift-away',
                ...props.options
            })
        }
        const bindInstance = () => {
            if (props.refKey) {
                const bind = inject<(el: HTMLElement | undefined) => void>(`bind[${props.refKey}]`)
                if (bind) {
                    bind(tippyRef.value)
                }
            }
        }
        onMounted(() => {
            init()
            bindInstance()
        })
        return {
            tippyRef
        }
    }
})
