
import { defineComponent } from 'vue'
import Loading from '@/global-components/loading.vue'
import Toast from 'primevue/toast'

export default defineComponent({
    components: {
        Loading,
        Toast
    }
})
