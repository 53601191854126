import axios from 'axios'
import store from '@/store'

export const instance = axios.create({
    baseURL: 'https://api.qq9.co' // prod
})

instance.interceptors.request.use(
    (config: any) => {
        const hostname = window.location.host
        if (hostname && hostname !== 'localhost:8080') {
            const [type] = hostname.split('.').slice(-1)
            const domainType = type || 'com'
            config.baseURL = `https://api.qq9.${domainType}`
        }

        const storage = window.localStorage.getItem('lotto')
        if (storage) {
            const user: { accessToken: string } = JSON.parse(storage)
            config.headers.Authorization = `Bearer ${user.accessToken}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (config) => {
        return config
    },
    (error) => {
        if (error && error.response && error.response.status === 401) {
            store.commit('logout')
        }

        return Promise.reject(error)
    }
)
