import { permission } from '@/type/Permission'
const state = () => {
    return {
        menu: [
            {
                icon: 'HomeIcon',
                pageName: permission.DASHBOARD,
                title: 'Dashboard',
                isSubMenu: false,
                permissionKey: permission.DASHBOARD
            },
            {
                icon: 'ApertureIcon',
                pageName: permission.LOTTO,
                title: 'หวย',
                isSubMenu: false,
                permissionKey: permission.LOTTO
            },
            {
                icon: 'UsersIcon',
                pageName: 'size-menu-usermanage',
                title: 'บัญชี',
                isSubMenu: true,
                subMenu: [
                    {
                        icon: '',
                        pageName: permission.USER_MANAGE,
                        title: 'ลูกค้า',
                        permissionKey: permission.USER_MANAGE
                    },
                    {
                        icon: '',
                        pageName: permission.ADMIN_MANAGE,
                        title: 'ผู้ดูแลระบบ',
                        permissionKey: permission.ADMIN_MANAGE
                    }
                ]
            },
            {
                icon: 'DollarSignIcon',
                pageName: 'size-menu-payment',
                title: 'การเงิน',
                isSubMenu: true,
                subMenu: [
                    {
                        icon: '',
                        pageName: permission.BANK_MANAGE,
                        title: 'บัญชีธนาคาร',
                        permissionKey: permission.BANK_MANAGE
                    },
                    {
                        icon: '',
                        pageName: permission.DEPOSIT,
                        title: 'ฝากเงิน',
                        permissionKey: permission.DEPOSIT
                    },
                    {
                        icon: '',
                        pageName: permission.WITHDRAW,
                        title: 'ถอนเงิน',
                        permissionKey: permission.WITHDRAW
                    },
                    {
                        icon: '',
                        pageName: permission.TRANSFER,
                        title: 'ระบบโอนเงิน',
                        permissionKey: permission.TRANSFER
                    }
                ]
            },
            {
                icon: 'FileTextIcon',
                pageName: 'side-menu-report',
                title: 'รายงาน',
                isSubMenu: true,
                subMenu: [
                    {
                        icon: '',
                        pageName: permission.BILL_REPORT,
                        title: 'รายการแทง',
                        permissionKey: permission.BILL_REPORT
                    },
                    {
                        icon: '',
                        pageName: permission.BILL_SUMMARY,
                        title: 'สรุป',
                        permissionKey: permission.BILL_SUMMARY
                    },
                    {
                        icon: '',
                        pageName: permission.COMM_SUMMARY,
                        title: 'สรุปค่าคอม',
                        permissionKey: permission.COMM_SUMMARY
                    },
                    {
                        icon: '',
                        pageName: permission.COMM_SUMMARY_CASINO_GAME,
                        title: 'สรุปค่าคอม (คาสิโน/เกม)',
                        permissionKey: permission.COMM_SUMMARY_CASINO_GAME
                    },
                    {
                        icon: '',
                        pageName: 'COMM_SUMMARY_CASINO_GAME_V2',
                        title: 'สรุปค่าคอม (คาสิโน/เกม) V2',
                        permissionKey: permission.COMM_SUMMARY_CASINO_GAME
                    },
                    {
                        icon: '',
                        pageName: permission.BILL_PENDING,
                        title: 'รอผลเดิมพัน',
                        permissionKey: permission.BILL_PENDING
                    },
                    {
                        icon: '',
                        pageName: 'ReportCasinoGame',
                        title: 'รายงาน Casino / Game',
                        permissionKey: permission.GAME_REPORT
                    },
                    {
                        icon: '',
                        pageName: 'ReportCasinoGameV2',
                        title: 'รายงาน Casino / Game V2',
                        permissionKey: permission.GAME_REPORT
                    }
                ]
            },
            {
                icon: 'FileTextIcon',
                pageName: 'side-menu-more',
                title: 'อื่นๆ',
                isSubMenu: true,
                subMenu: [
                    {
                        icon: '',
                        pageName: permission.NEW_USER_DEPOSIT,
                        title: 'สมาชิกใหม่ฝากเงิน',
                        permissionKey: permission.NEW_USER_DEPOSIT
                    },
                    {
                        icon: '',
                        pageName: permission.PROFIT_REPORT,
                        title: 'รายงานสมาชิกฝาก - ถอน',
                        permissionKey: permission.PROFIT_REPORT
                    },
                    {
                        icon: '',
                        pageName: permission.APP_CONFIG,
                        title: 'ตั้งค่าเว็บ',
                        permissionKey: permission.APP_CONFIG
                    },
                    {
                        icon: '',
                        pageName: 'promotion',
                        title: 'ตั้งค่าโปรโมชัน',
                        permissionKey: permission.APP_CONFIG
                    },
                    {
                        icon: '',
                        pageName: 'ReportPromotion',
                        title: 'รายงานโปรโมชัน',
                        permissionKey: permission.REPORT_PROMOTION
                    },
                    {
                        icon: '',
                        pageName: permission.PROVIDER,
                        title: 'จัดการค่ายเกม',
                        permissionKey: permission.PROVIDER
                    },
                    {
                        icon: '',
                        pageName: permission.PROVIDER_V2,
                        title: 'จัดการค่ายเกม V2',
                        permissionKey: permission.PROVIDER_V2
                    }
                ]
            },
            {
                icon: 'ApertureIcon',
                pageName: permission.PERMISSION,
                title: 'สิทธิ์การใช้งาน',
                isSubMenu: false,
                permissionKey: permission.PERMISSION
            },
            {
                icon: 'FramerIcon',
                pageName: permission.LOGS,
                title: 'Logs',
                isSubMenu: false,
                permissionKey: permission.LOGS
            },
            {
                icon: 'BarChartIcon',
                pageName: permission.TIERS,
                title: 'ระดับลูกค้า',
                isSubMenu: false,
                permissionKey: permission.TIERS
            },
            {
                icon: 'MessageCircleIcon',
                pageName: permission.SMS,
                title: 'ส่ง SMS',
                isSubMenu: false,
                permissionKey: permission.SMS
            }
        ]
    }
}

const getters = {
    menu: (state: { menu: Array<[]> }) => state.menu,
    menuPermission: (state: { menu: Array<[]> }, getter, rootState) => {
        let isAuthenticated = false
        const storage = window.localStorage.getItem('lotto')
        if (storage) {
            isAuthenticated = true
        }
        if (isAuthenticated) {
            const permission = rootState.adminDetail.permission.roles
            const newItem: any = []
            state.menu.forEach((e: any) => {
                if (e.isSubMenu) {
                    const subMenuCheckKey = e.subMenu.filter((s) => {
                        return permission.includes(s.permissionKey)
                    })
                    if (subMenuCheckKey.length > 0) {
                        e.subMenu = subMenuCheckKey
                        newItem.push(e)
                    }
                } else {
                    const checkKey = permission.includes(e.permissionKey)
                    if (checkKey) {
                        newItem.push(e)
                    }
                }
            })
            return newItem
        } else {
            return []
        }
    }
}

const actions = {}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
