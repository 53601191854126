import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b18be316"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader",
  "align-center": "",
  "justify-center": "",
  row: "",
  "fill-height": ""
}
const _hoisted_2 = { class: "centerScreen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ProgressSpinner)
        ])
      ]))
    : _createCommentVNode("", true)
}